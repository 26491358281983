//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-76:_6088,_2504,_6944,_1656,_9666,_3752,_8040,_6512;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-76')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-76', "_6088,_2504,_6944,_1656,_9666,_3752,_8040,_6512");
        }
      }catch (ex) {
        console.error(ex);
      }